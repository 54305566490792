<template>
  <div class="border-l-4 rounded-e-md p-4" :class="colors[color].box">
    <div class="flex">
      <div class="flex-shrink-0">
        <InformationCircleIcon class="h-5 w-5" :class="colors[color].icon" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <p class="text-sm" :class="colors[color].text">
          <span v-html="text"></span>
          {{ ' ' }}
          <slot></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
withDefaults(
  defineProps<{
    text?: string
    color?: string
  }>(),
  {
    color: 'blue'
  }
)

const colors: { [key: string]: any } = {
  blue: {
    box: 'border-blue-400 bg-blue-100',
    icon: 'text-blue-400',
    text: 'text-blue-700'
  },
  amber: {
    box: 'border-amber-400 bg-amber-100',
    icon: 'text-amber-400',
    text: 'text-amber-700'
  }
}
</script>
