import { supabase } from '@/lib/supabaseClient'
import type { IssuerListItem } from '@/types/types'
import { useQuery } from '@tanstack/vue-query'

export function useIssuerListQuery() {
  return useQuery<any>({
    queryKey: ['issuerList'],
    staleTime: Infinity,
    queryFn: async () => supabase.from('CertificateIssuers').select('name, id').order('name'),
    select: ({ data }: any) => new Map(data.map((item: IssuerListItem) => [item.id, item]))
  })
}
