import i18n from '@/i18n'

const languageImports = import.meta.glob('@/i18n/translations/!(en).json')
Object.entries(languageImports).forEach(([key, value]) => {
  delete languageImports[key]
  languageImports[key.split('/').reverse()[0].replace('.json', '')] = value
})

export default async (locale?: string): Promise<string> => {
  if (!locale) {
    locale = localStorage.getItem('locale') || window.navigator.languages[0].split('-')[0]
  }
  if (!!languageImports[locale]) {
    const { default: translations }: any = await languageImports[locale]()
    i18n.global.setLocaleMessage(locale, translations)
  }
  i18n.global.locale.value = locale as any
  localStorage.setItem('locale', locale)
  // Force locale until we have translations
  return 'en'
  // return locale
}
