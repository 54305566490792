<template>
  <div
    data-test-id="expiry-bg"
    class="rounded-md w-12 h-12 flex items-center justify-center"
    :class="classes[color].bg"
  >
    <div class="text-center scale-75 font-bold" :class="classes[color].text">
      <div v-if="!expired">
        <div class="text-2xl -mt-2">{{ quantityToDisplay }}</div>
        <div class="text-uppercase text-xs uppercase -mt-1">{{ unitToDisplay }}</div>
      </div>
      <XMarkIcon v-if="expired" class="h-10 w-10" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { formatDistanceToNowStrict, isPast, add, isBefore } from 'date-fns'
const props = defineProps<{ expiryDate: string | null }>()

let expired: boolean
let quantityToDisplay: string
let unitToDisplay: string
let color: 'red' | 'amber' | 'blue' | 'green' = 'blue'
const classes = {
  red: { bg: 'bg-red-100', text: 'text-red-500' },
  amber: { bg: 'bg-amber-100', text: 'text-amber-500' },
  blue: { bg: 'bg-blue-100', text: 'text-blue-500' },
  green: { bg: 'bg-green-100', text: 'text-green-500' }
}

if (!!props.expiryDate) {
  const expiryDate = new Date(props.expiryDate)
  expired = isPast(expiryDate)
  let formatted = formatDistanceToNowStrict(expiryDate)
  const [quantity, unit] = formatted.split(' ')
  quantityToDisplay = quantity
  unitToDisplay = { years: 'yrs', months: 'mths', days: 'days', hours: 'hrs' }[unit] || unit
  if (expired) {
    color = 'red'
  } else {
    const today = new Date()
    const sixMonthsTime = add(today, { months: 6 })
    if (isBefore(sixMonthsTime, expiryDate)) {
      color = 'green'
    } else if (isBefore(today, expiryDate)) {
      color = 'amber'
    } else {
      color = 'red'
    }
  }
}
</script>
